import { type PageProps } from 'gatsby'
import * as React from 'react'
import { EngageRings } from '../../components/EngageRings'
import { Layout } from '../../components/Layout'
import { SEO } from '../../components/SEO'
import { useEngageRingParams } from '../../hooks/useEngageRingParams'

export const Head = () => (
    <SEO
        title="安い婚約指輪・激安のエンゲージリングなら低価格で高品質なミスプラチナ"
        description="低価格の婚約指輪はメーカー直販ミスプラチナがおすすめ。プラチナのペアリング一覧。"
        page="engagering"
    />
)

const EngageRingPage: React.FC<PageProps> = ({ location }) => {
    const params = useEngageRingParams(location.search, true)
    return (
        <Layout breadcrumbs={[{ name: '安い婚約指輪・エンゲージリング' }]}>
            <EngageRings {...params} />
        </Layout>
    )
}

export default EngageRingPage
